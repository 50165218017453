/* App.css */

/* Resetting default margin and padding */
body, h1, h2, p {
  margin: 0;
  padding: 0;
}

/* Page Container */
.page-container {
  position: relative;
  height: 100vh; /* Full height of the viewport */
  overflow: hidden;
}

/* Background Image */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #e4766d, #f4d547,  #e98db4);
  background-size: 400% 400%;
  animation: gradientAnimation 30s ease infinite;
  z-index: -1; /* Behind the content */
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Content Container */
.content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Above the background image */
  text-align: center;
  color: #fff; /* White text color */
}

/* Logo */
.logo {
  height: auto;
  position: absolute;
  top: 25px;
  left: 25px;
  display: flex;
}
.logo img{
width: 40px;
margin-right: 5px;
}
.logo p{
display: inline;
font-size: 30px;
color: #fff;

  }
/* Title */
.title {
  font-size: 5rem;
  margin-bottom: 40px;
  margin-top: 0;
}

/* Heading 2 */
h2 {
  font-size: 40px;
}

/* Paragraph */
.qbox{
  display: flex;
  width: 100%;
  gap: 17px;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .qbox{
    flex-direction: column;
  }
}
.paragraph {
  font-size: 25px;
  border: 1px #fff solid;
  border-radius: 30px;
  width: 45%;
  padding: 15px 0px;
  font-size: 18px;
}
@media (max-width: 450px) {
  .paragraph {
    font-size: 25px;
    border: 1px #fff solid;
    border-radius: 30px;
    width: 100%;
    padding: 15px 0px;
    font-size: 18px;
  }
}
.paragraph strong{
  font-size: 25px;
}
/* Link */
.contact-us a {
  color: #fff; /* White link color */
  text-decoration: none;
  cursor: pointer;
}
.contact-us{
  color: #fff; /* White link color */
  width: 100%;
  font-size: 18px;
  height: auto;
  position: absolute;
  bottom: 20px;
  text-align: center;
}
